import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    private path = '';

    constructor(private http: HttpClient) { }

    getLinks(locale: string) {
        this.path = `api/link/?locale=${locale}&populate=%2A`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getGraphs(locale: string) {
        this.path = `api/graph/?locale=${locale}&populate=%2A`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getCategory(id: any) {
        this.path = `api/categories/${id}/`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getCategory2(id: any, limit: any, locale: any, page: any) {
        this.path = `api/pages/?populate=*&filters[categories][id][$eq]=${id}&pagination[page]=${page}&pagination[pageSize]=${limit}&locale=${locale}&sort[0]=published%3Adesc`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getCategory3(id: any, limit: any, locale: any, page: any, pageID: any) {
        this.path = `api/pages/?populate=*&filters[id][$ne]=${pageID}&filters[categories][id][$eq]=${id}&pagination[page]=${page}&pagination[pageSize]=${limit}&locale=${locale}&sort[0]=published%3Adesc`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getEvents(id: any, locale: any, from: any, to: any) {
        
        this.path = `api/pages/?filters[event_date][$gte]=${from}&filters[event_date][$lte]=${to}&locale=${locale}`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getPagesLimit(id: any, limit: any, locale: string) {
        this.path = `api/pages/?populate=*&filters[categories][id][$eq]=${id}&pagination[page]=1&pagination[pageSize]=${limit}&locale=${locale}&sort[0]=published%3Adesc`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getSliders() {
        this.path = `api/sliders/?populate=%2A&pagination[limit]=-1`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getMenus(locale: string) {
        this.path = `api/menus/?locale=${locale}&populate=%2A&pagination[limit]=-1`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getBanners() {
        this.path = `api/banners/?populate=%2A&pagination[limit]=-1`
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getUsefulLinks() {
        this.path = 'api/useful-links/?populate=%2A&pagination[limit]=-1';
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getPage(id: any) {
        this.path = `api/pages/${id}/?populate=%2A`
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    getPages(locale: string) {
        this.path = `api/pages/?locale=${locale}&populate=%2A&pagination[limit]=-1`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    postEmail(obj: any) {
        this.path = "https://cms.arcci.gr/api/email/";
        return this.http.post<any>(this.path, obj);
    }

    search(locale: string, query: any, page: any, pageSize: any) {
        this.path = `api/pages/?sort=published%3Adesc&locale=${locale}&${query}&populate=cover&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

    gePopup(locale: string) {
        this.path = `api/popup/?locale=${locale}&populate=%2A`;
        return this.http.get<any[]>(environment.apiUrl + this.path);
    }

}
