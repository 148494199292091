<div class="container">
    <div class="row">
        <div class="col-md-9">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <p><img style="margin-right: 30px; float: left;"
                                src="https://cms.arcci.gr/uploads/members_055c1b8acb.jpg"
                                alt="μελη επιμελητηριου αργολιδας" class="img-fluid">
                        </p>
                        <h2>Μέλη του Επιμελητηρίου Αργολίδας</h2>
                        <p>Μέλη του Επιμελητηρίου Αργολίδας είναι υποχρεωτικά όλα τα φυσικά ή νομικά πρόσωπα που ασκούν
                            επιχειρηματική
                            δραστηριότητα έχουν την έδρα τους ή είναι εγκατεστημένα στο Νομό Αργολίδας.</p>

                        <p>Ο αριθμός των Μελών του Επιμελητηρίου Αργολίδας την <strong>{{this.graphs && this.graphs.date}}</strong> ήταν
                            <span><strong>{{this.graphs && this.graphs.sunolo_melon}}</strong>.</span> Από το σύνολο των μελών του υπάγονται στο
                            <strong>Εμπορικό
                                Τμήμα</strong>:<strong> <strong>{{this.graphs && this.graphs.emporiko_tmima}}</strong></strong>,
                            <strong>Μεταποιητικό Τμήμα</strong>:<strong>
                                <strong>{{this.graphs && this.graphs.metapoiitiko_tmima}}</strong></strong> και <strong>Τμήμα Υπηρεσιών</strong>:<strong>{{this.graphs && this.graphs.tmima_upiresion}}</strong>.
                        </p>
                    </div>
                    <div class="col-md-12">

                        <h2>Γραφήματα</h2>
                        <ngx-charts-pie-grid [scheme]="colorScheme" [results]="single" (select)="onSelect($event)" [label]="'Σύνολο'">
                        </ngx-charts-pie-grid>
                    </div>
                    <div class="col-md-12">
                        <p>Επί του γενικού συνόλου των μελών {{this.graphs && this.graphs.sunolo_melon}} περιλαμβάνονται τα ακόλουθα <strong>ειδικά
                                μητρώα
                                {{this.graphs && this.graphs.sunolo_eidika_mitroa}}
                                μελών</strong>:</p>
                        
                        <ngx-charts-bar-horizontal  [scheme]="colorScheme" [results]="single2"
                            [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                            [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [legendTitle]='"Ετικέτες"'
                            [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (select)="onSelect($event)"
                            (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                        </ngx-charts-bar-horizontal>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <app-banners></app-banners>
        </div>
    </div>
</div>