import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ApiService } from '../app.service';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '@ngx-meta/core';
import { MetadataService } from '../metadata.service';
import { isPlatformBrowser } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  constructor(private api: ApiService, private _Activatedroute: ActivatedRoute, private sanitizer: DomSanitizer, private spinner: NgxSpinnerService,
    private metaService: Meta, private title: Title, private router: Router, @Inject(PLATFORM_ID) private platformId: object, private _location: Location) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  id: any
  isBrowser: any
  article: any
  apiurl = environment.apiUrlPublic
  body: any
  cover: any
  displayCustom: boolean = false;
  activeIndex: number = 0;
  websiteUrl = ''
  moreArticles = []
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  async ngOnInit() {

    this._Activatedroute.params.subscribe(async params => {
      this.spinner.show();
      console.log(this.router.url)
      // Defaults to 0 if no query param provided.
      this.id = params['id'] || 0;
      this.websiteUrl = `https://arcci.gr${this.router.url}`
      let page: any = await this.api.getPage(this.id).toPromise()

      this.article = page.data
      // this.body = this.sanitizer.bypassSecurityTrustHtml(this.article.attributes.body)
      if (this.article.attributes.body) {
        if (this.isBrowser == false) {
          this.body = this.sanitizer.bypassSecurityTrustHtml(this.article.attributes.body)
        }
        else if (this.isBrowser == true) {
          let parser = new DOMParser();
          let doc: any = parser.parseFromString(this.article.attributes.body, "text/html");
          let images = doc.getElementsByTagName('img');

          for (let i = 0; i < images.length; i++) {
            images[i].classList.add('img-fluid');
          }

          let tables = doc.getElementsByTagName('table');


          for (let i = 0; i < tables.length; i++) {

            tables[i].style.all = 'initial'
            tables[i].classList.add('table');
            tables[i].parentNode.classList.add('table-responsive')
          }

          this.body = new XMLSerializer().serializeToString(doc)


          this.body = this.sanitizer.bypassSecurityTrustHtml(this.body)
        }
      } else {
        this.body = ''
      }

      this.article.attributes.published = new Date(this.article.attributes.published).toLocaleDateString()
      this.metaService.updateTag({ property: 'og:title', content: this.article.attributes.title })


      this.title.setTitle(this.article.attributes.title);
      if (this.article.attributes.cover.data) {
        this.cover = `${this.apiurl}${this.article.attributes.cover.data.attributes.url}`
        this.metaService.updateTag({ property: 'og:image', content: this.cover })
      }

      // this.metadataService.updateMetadata({
      //   title: this.article.attributes.title,
      //   description: this.article.attributes.title,
      //   author: "Επιμελητήριο Αργολίδας",
      //   image: this.cover || 'https://arcci.gr/assets/logo_trans.png'
      // });



      // this.metaService.updateTag({ property: 'og:description', content: this.article.attributes.title });
      // this.metaService.updateTag({ property: 'og:url', content: `https://arcci.gr/#${this.router.url}` });

      if (this.article.attributes.files.data) {
        this.article.attributes.files.data.forEach((element: any) => {
          element.attributes.url = `${this.apiurl}${element.attributes.url}`
        });
      }

      if (this.article.attributes.photos.data) {
        this.article.attributes.photos.data.forEach((element: any) => {
          console.log(element.attributes)
          element.attributes.url = `${this.apiurl}${element.attributes.url}`
        });
      }

      let catId = this.article.attributes.categories?.data[0]?.id
      this.getarticles(catId)
      this.spinner.hide();
    });


  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

  backClicked() {
    this._location.back();
  }

  async getarticles(id) {

    let moreArticles: any = await this.api.getCategory3(id, 3, 'el', 1, this.id).toPromise()
    moreArticles.data.forEach((element: any) => {

      var date2 = new Date(element.attributes.published);
      var n = date2.toLocaleDateString('el-GR', { timeZone: 'Europe/Athens' })
      element.attributes.published = n

      if (element.attributes.cover.data) {
        element.cover = `${this.apiurl}${element.attributes.cover.data.attributes.url}`
      } else {
        element.cover = `/assets/logo_trans.png`
      }


    });
    this.moreArticles = moreArticles.data


  }
}
