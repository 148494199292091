import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css']
})
export class BannersComponent implements OnInit {
  apiUrl = environment.apiUrlPublic

  constructor(private api: ApiService) { }


  images: any[] = [];


  async ngOnInit() {
    let banners: any = await this.api.getBanners().toPromise();
    this.images = banners.data;
    //create the full path for each banner
    this.images.forEach((image: { attributes: { banner: { data: { attributes: { url: string }}}}}) => {
      image.attributes.banner.data.attributes.url = this.apiUrl + image.attributes.banner.data.attributes.url;
    })
  }

}
