import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from './page/page.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CategoryComponent } from './category/category.component';
import { ContactComponent } from './contact/contact.component';
import { ChamberMembersComponent } from './chamber-members/chamber-members.component';
import { SearchComponent } from './search/search.component';
import { NewsletterComponent } from './newsletter/newsletter.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
  },
  {
    path: 'page/:id',
    component: PageComponent,
  },
  {
    path: 'category/:id/:page',
    component: CategoryComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'chamber-members',
    component: ChamberMembersComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabled',scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
