import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
  results: number[] = []; //the results of the searching will be stored here
  query: string; //the text that user wants to search. With 2-way data binding, it is updated every time input's text is updated
  language = 'el'

  constructor(private api: ApiService, private translate: TranslateService, private router: Router) { }

  async ngOnInit() {
    //get the current language
    this.language  = this.translate.currentLang === undefined ? 'el' : this.translate.currentLang;
  }

  //the goal of this function is to find the ids of all pages that contain at least one word of the query, ie the text that user searched
  search() {
    //clear the results from the previous search
    this.results = [];
    const keys: string[] = this.query.split(" ");
    console.log(keys)
   
    this.router.navigate(['/search'], {queryParams: {queryText: this.query}});
  }


  isGreekLetter(letter: string): boolean {
    return (letter >= 'α' && letter <= 'ω')  //greek lowercase letter with no tonos
        || (letter >= 'Α' && letter <= 'Ω')  //greek uppercase letter with no tonos
        || (['ά', 'έ', 'ή', 'ί', 'ύ', 'ό', 'ώ'].includes(letter))  //greek lowercase with tonos
        || (['Ά', 'Έ', 'Ή', 'Ί', 'Ύ', 'Ό', 'Ώ'].includes(letter)); //greek uppercase with tonos
  }

  isLatinLetter(letter: string): boolean {
    return (letter >= 'a' && letter <= 'z')  //latin lowercase letter
        || (letter >= 'A' && letter <= 'Z'); //latin uppercase letter
  }

  isNumber(letter: string): boolean {
    return (letter >= '0' && letter <= '9');
  }

  isFinalLetter(letter: string): boolean {
    //returns true if the given letter signals the termination of a word
    return (letter === "." || letter === "," || letter === " "); 
  }

  //makes word lowercase and replaces greek toned characters with their corresponding non-toned
  filterWord(word: string): string {
    word = word.toLowerCase();
    word = this.replaceTonedGreekCharacters(word);
    return word;
  }

  //Replaces the greek letter with tonos that exist in the given word, with the same letters without tonos
  replaceTonedGreekCharacters(word: string): string {
    const tonedCharacters: string[] = ['ά', 'έ', 'ή', 'ί', 'ύ', 'ό', 'ώ'];
    const nonTonedCharacters: string[] = ['α', 'ε', 'η', 'ι', 'υ', 'ο', 'ω'];

    let index: number = 0;
    let newWord: string = "";
    for (let i = 0; i < word.length; i++) {
      index = tonedCharacters.findIndex((letter: string) => letter === word[i]);
      if (index !== -1) { //you found greek letter with tonos
        newWord += nonTonedCharacters[index];
        continue;
      } 
      newWord += word[i]; 
    }
    return newWord;
  }


  cleanText(text: string): string[] {
    const words: string[] = []; //an array with all words that we will see left if we clean the given text
    let word: string = "";
    let i: number;
    let letter: string;
    const size = text.length;
    let symbol: string = null; //either null or <. Indicates whether we found the start of a html tag


    for (i = 0; i < size; i++) {
      letter = text[i];
      if (letter === "<") { //a html tag just started
        symbol = "<";
        if (word !== "") { //add the word that you already have built, in the words array
          words.push(word);
          word = "";
        }
      } else {
        if (letter === ">") {
          if (symbol === "<") { //a html tag just ended
            symbol = null;
          }
        } else {
          if (symbol === "<") { //you found alphabetical letters inside a html tag, ie <body>. Ignore them
            continue;
          } else { //you found alphabetical letters outside of html tag. They might be part of word
            if (this.isGreekLetter(letter) || this.isLatinLetter(letter) || this.isNumber(letter)) { //you did found letters/numbers. Append them to your word
              word += letter;
            } else if (this.isFinalLetter(letter)){ //you found non-alphabetical letters, that signal the termination of your word, ie '.'
              words.push(word);
              word = "";
            }
          }
        }
      }
    }
    //push any word left
    if (word !== "") {
      words.push(word);
    }
    return words;
  }

}
